import React from "react";
import SudokuMainContainer from "../../../components/SudokuMainContainer";

function Backtracking() {
  const symbol = "s";
  const start = async (
    gridSize,
    grid,
    setSteps,
    setSearchTerm,
    speed,
    colors,
    delay,
    stopRef,
    gridColor
  ) => {
    var steps = 0;
    const isValid = (board, row, col , num) => {
        //check row and col 
        for (let i = 0; i < 9; i++) {
            if (board[row][i] === num || board[i][col] === num) {
              return false;
            }
        }
    
        //check same 3*3 box
        const subgridRow = Math.floor(row / 3) * 3;
        const subgridCol = Math.floor(col / 3) * 3;
        for (let i = 0; i < 3; i++) {
          for (let j = 0; j < 3; j++) {
            if (board[subgridRow + i][subgridCol + j] === num) {
              return false;
            }
          }
        }
       
        return true;
      }
    
      const solveSudoku = async(block,steps) => {

            if(isBoardFilled(block)){
                return true;
            }
            
            const [row, col] = findNextEmptyCell(block);
    
            for(let i = 1; i <= 9 ; i++){
                if(isValid(block,row,col,i)){
                    block[row][col] = i;
                    steps += 1;
                    setSteps(steps)
                    await delay(1000-speed)
                    if(await solveSudoku(block,steps)){
                        return true;
                    }
    
                    block[row][col] = ' '
                    await delay(1000-speed)
                }
            }
            return false;
      }
    
      const findNextEmptyCell = (block) => {
        for(let i = 0; i < 9; i++){
            for(let j = 0; j < 9; j++){
                if(block[i][j] == ' '){
                    return [i,j];
                }
            }
        }
        return [-1,-1];
      }
    
      const isBoardFilled = (block) => {
        for(let i = 0; i < 9; i++){
            for(let j = 0; j < 9; j++){
                if(block[i][j] == ' '){
                    return false;
                }
            }
        }
        return true;
      }
          
    await solveSudoku(grid,steps);
  
  };


  return (
    <SudokuMainContainer
      symbol={symbol}
      title={"Sudoku Solver"}
      algo={start}
    />
  );
}

export default Backtracking;
