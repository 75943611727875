import { useEffect, useRef, useState } from "react";
import { colors } from "../../themes/colors";
import PathCard from "../PathContainer";
import { MdBoy } from "react-icons/md";
import { BiHome } from "react-icons/bi";
import { GiBrickWall } from "react-icons/gi";
import SudokuCard from "../SudokuContainer/SudokuCard";

const SudokuMainContainer = ({ algo, isSorted, symbol, title }) => {
  const [gridSize, setGrid] = useState(9);
  const [grid, setMatrix] = useState([[0]]);
  const [speed, setSpeed] = useState(800);
  const [steps, setSteps] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [gridColor,setGridColor] = useState([[0]]);
  const stopRef = useRef(false);
  const componentRef = useRef(null);
  const [action, currentAction] = useState(0);

  useEffect(() => {
    generateRandomGrid();
  }, [gridSize]);


  const generateSudoku = () => {
      var grid = [];
      const size = 9;
      const emptyCells = 50; // Adjust the number of empty cells as desired
    
      // Initialize the grid with zeros
      for (let i = 0; i < size; i++) {
        grid.push(Array(size).fill(0));
      }
    
      // Helper function to check if a value is valid in a specific position
      const isValid = (row, col, num) => {
        // Check row and column
        for (let i = 0; i < size; i++) {
          if (grid[row][i] === num || grid[i][col] === num) {
            return false;
          }
        }
    
        // Check the 3x3 box
        const boxRow = Math.floor(row / 3) * 3;
        const boxCol = Math.floor(col / 3) * 3;
        for (let i = 0; i < 3; i++) {
          for (let j = 0; j < 3; j++) {
            if (grid[boxRow + i][boxCol + j] === num) {
              return false;
            }
          }
        }
    
        return true;
      };
    
      // Helper function to solve the Sudoku using backtracking
      const solveSudoku = () => {
        for (let row = 0; row < size; row++) {
          for (let col = 0; col < size; col++) {
            if (grid[row][col] === 0) {
              for (let num = 1; num <= size; num++) {
                if (isValid(row, col, num)) {
                  grid[row][col] = num;
    
                  if (solveSudoku()) {
                    return true;
                  }
    
                  // If the current configuration is not solvable, backtrack
                  grid[row][col] = 0;
                }
              }
    
              // If no number is valid, it means the current configuration is not solvable
              return false;
            }
          }
        }
    
        // If all cells are filled, it means the Sudoku is solved
        return true;
      };
    
      // Solve the Sudoku
      solveSudoku();
    
      const rotateArray90Degrees = (arr) => {
        const rows = arr.length;
        const cols = arr[0].length;
      
        // Create a new empty array with swapped dimensions
        const rotatedArray = new Array(cols).fill().map(() => new Array(rows));
      
        for (let row = 0; row < rows; row++) {
          for (let col = 0; col < cols; col++) {
            rotatedArray[col][rows - 1 - row] = arr[row][col];
          }
        }
      
        return rotatedArray;
      };

      // Remove some pre-filled cells to create a question
      let remainingEmptyCells = emptyCells;
      while (remainingEmptyCells > 0) {
        const row = Math.floor(Math.random() * size);
        const col = Math.floor(Math.random() * size);
        if (grid[row][col] !== 0) {
          grid[row][col] = 0;
          remainingEmptyCells--;
        }
      }

      grid = rotateArray90Degrees(grid);
      const newArray = grid.map((row) => [...row]);
      setGridColor(newArray);

      for (let row = 0; row < 9; row++) {
        for (let col = 0; col < 9; col++) {
          if (grid[row][col] === 0) {
            grid[row][col] = ' ';
          }
        }
      }
    
      setMatrix(grid);
  };
  
  const generateRandomGrid = () => {
    generateSudoku()
    
  };

  const Grid = () => {
    var dim = (0.8 * window.innerHeight) / gridSize;
    var mt = ((0.005 * window.innerHeight) / 2) * gridSize;
    return (
      <div
        ref={componentRef}
        className="w-full h-full justify-center mx-auto align-middle"
        style={{ paddingTop: mt }}
      >
        {grid.map((i, a) => {
          return (
            <div
              key={symbol + `${a}`}
              style={{
                height: dim,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {i.map((j, b) => {
                return (
                  <div
                    key={symbol + `${a}` + `${b}`}
                    style={{
                      width: dim,
                      height: dim,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      backgroundColor: gridColor[a][b],
                      borderWidth: 2,
                      borderColor: "#212121",
                      borderBottomColor:
                        (a + 1) % 3 == 0 && a + 1 != 9 ? "#00aaff" : "#212121",
                      borderRightColor:
                        (b + 1) % 3 == 0 && b + 1 != 9 ? "#00aaff" : "#212121",
                      overflow: "hidden",
                      animation:
                        gridColor[a][b] == "orange" ||
                        gridColor[a][b] == "green"
                          ? "scale-up-center 0.3s ease-out forwards"
                          : "none",
                    }}
                  >
                    <p style={{ fontSize: 18, fontWeight:"bold",color: gridColor[a][b] != ' ' ? "#00aaff" : "#f0f0f0" }}>{j}</p>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  const Display = () => {
    return (
      <div className="w-full h-full flex justify-center align-middle">
        <Grid />
      </div>
    );
  };


  function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const handlePause = () => {
    stopRef.current = true;
  };

  return (
    <SudokuCard
      refresh={() => {
        generateRandomGrid();
      }}
      title={title}
      start={() => {
          algo(
            gridSize,
            grid,
            setSteps,
            setSearchTerm,
            speed,
            colors,
            delay,
            stopRef,
            gridColor
          );
      }}
      setSpeed={(e) => {
        setSpeed(e);
      }}
      steps={steps}
      searchTerm={searchTerm}
      setPause={handlePause}
    >
      <div className="w-[100%] h-[100%]">
        <Display />
      </div>
    </SudokuCard>
  );
};

export default SudokuMainContainer;
